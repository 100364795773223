import { useEffect } from "react";
import ReactGA from "react-ga4";

const Analytics = () => {
  useEffect(() => {
    ReactGA.initialize("G-C7B88R72CM");
  }, []);
  return null;
};

export default Analytics;
