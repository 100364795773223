import Navbar from "../Components/Navbar.js";
import Main from "../Components/Main";
import Home from "../Components/Home";
import Programs from "../Components/Programs";
import ProgramsApple from "../Components/ProgramsApple.js";
import Counter from "../Components/Counter";
import Steps from "../Components/Steps";
import Pricing from "../Components/Pricing";
import Scroller from "../Components/scroller.js";
import Ques from "../Components/Ques.js";
import Footer from "../Components/Footer.js";
import Register from "../Components/Register.js";
import Grid from "../Components/gridDemo";
import Timeline from "../Components/Timeline.js";
import { ShootingStars } from "../Components/ui/shooting-stars.jsx";
import { StarsBackground } from "../Components/ui/stars-background";
import ProgressBar from "../Components/ProgressBar"; // Adjust the path if necessary
import Analytics from "../Components/Analytics.jsx";

import { black } from "tailwindcss/colors.js";
function HomePage() {
  return (
    <div className="">
      <Analytics />
      <div
        style={{
          position: "fixed", // Position absolutely within the viewport
          top: 0,
          left: 0,
          width: "100vw", // Full width of the viewport
          maxWidth: "100vw", // Full width of the viewport
          height: "100vh", // Full height of the viewport
          overflow: "hidden", // Hide any extra overflow
          zIndex: "-1", // Send it to the background
          backgroundColor: "black",
        }}
      >
        <ShootingStars />
        <StarsBackground />
      </div>

      <Navbar />
      <Main />
      <Home />
      {/* <Scroller /> */}
      {/* <ProgramsApple /> */}

      <Programs />
      <Timeline />
      {/* <Steps /> */}
      <Pricing />
      <Ques />
      <Footer />
    </div>
  );
}

export default HomePage;
