import React, { useEffect } from "react";

const Register = () => {
  useEffect(() => {
    // Dynamically add the Tally script to the head
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    // Cleanup the script on unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="w-full h-full" border-4 border-black style={{ margin: 0 }}>
      <iframe
        data-tally-src="https://tally.so/r/wQYq21?transparentBackground=1"
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Starter101 Registration"
        style={{}}
      ></iframe>
    </div>
  );
};

export default Register;
