import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink as Hlink } from "react-router-hash-link";
import { FiArrowUpRight } from "react-icons/fi"; // Import the arrow icon
import Logo from "../media/LogoTransparentBlack.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [textColor, setTextColor] = useState("text-white");
  const [isScrolled, setIsScrolled] = useState(false); // Scroll state

  const menuRef = useRef();
  const closeButtonRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen((prev) => {
      if (prev) setTextColor("text-black");
      else setTextColor("text-white");
      return !prev;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        closeButtonRef.current &&
        !closeButtonRef.current.contains(event.target)
      ) {
        if (isOpen) {
          setIsOpen(false);
          setTextColor("text-black");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 ease-in-out text-black  ${
        isScrolled ? "backdrop-blur-lg" : ""
      }`}
      style={{
        boxShadow: isScrolled ? "0 8px 32px 0 rgba(0, 0, 0, 0.75)" : "none", // Shadow based on scroll
      }}
    >
      <div
        className={`flex ${
          isOpen ? "backdrop-blur-lg " : "items-center justify-center"
        }  space-x-8 md:order-1 max-w-screen-2xl flex flex-wrap  mx-auto p-4`}
      >
        <div
          className={`flex ${
            isOpen
              ? "backdrop-blur-lg items-center justify-center"
              : "items-center justify-center"
          }  space-x-8 md:order-1`}
        >
          <Hlink smooth to="/#">
            <a className="mix-blend-multiply flex items-center justify-end space-x-3 rtl:space-x-reverse">
              {/* <span className="font-bold text-4xl pb-[4px]">Starter</span> */}
              <img
                src={Logo} // Use the imported logo here
                alt="Starter 101 Logo"
                className="w-27 h-9" // Adjust size as needed
              />
            </a>
          </Hlink>

          <div className={`hidden w-full md:flex md:w-auto`}>
            <ul className="flex flex-col items-center justify-center font-medium p-4 md:p-0 mt-4 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Hlink smooth to="/#programs">
                  <a
                    className="block py-2 px text-lg rounded hover:text-transparent 
    hover:bg-clip-text 
    hover:bg-gradient-to-r 
    hover:from-[#02f0ff] 
    hover:via-[#9b88ff] 
    hover:to-[#ff00a8]"
                  >
                    Programs
                  </a>
                </Hlink>
              </li>
              <li>
                <Hlink smooth to="/#how-it-works">
                  <a
                    className="block py-2 px text-lg rounded hover:text-transparent 
    hover:bg-clip-text 
    hover:bg-gradient-to-r 
    hover:from-[#02f0ff] 
    hover:via-[#9b88ff] 
    hover:to-[#ff00a8]"
                  >
                    How it works?
                  </a>
                </Hlink>
              </li>
              <li>
                <Hlink smooth to="/#faq">
                  <a
                    className="block py-2 px text-lg rounded hover:text-transparent 
     hover:bg-clip-text 
    hover:bg-gradient-to-r 
    hover:from-[#02f0ff] 
    hover:via-[#9b88ff] 
    hover:to-[#ff00a8]"
                  >
                    FAQ
                  </a>
                </Hlink>
              </li>
              <li>
                <Hlink smooth to="/#pricing">
                  <a
                    className="block py-2 px text-lg rounded hover:text-transparent 
     hover:bg-clip-text 
    hover:bg-gradient-to-r 
    hover:from-[#02f0ff] 
    hover:via-[#9b88ff] 
    hover:to-[#ff00a8]"
                  >
                    Pricing
                  </a>
                </Hlink>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex items-center space-x-3 md:space-x-6 md:order-2">
          <div className="font-medium hidden md:flex space-x-8"></div>

          <button
            onClick={toggleMenu}
            type="button"
            className={`inline-flex items-center p-1 w-10 h-10 justify-center ${
              isOpen || isScrolled
                ? "text-slate-100"
                : "text-slate-100 justify-between"
            } text-sm rounded-lg md:hidden`}
            ref={closeButtonRef}
          >
            {isOpen ? (
              <svg
                className="w-7 h-7"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-7 h-7"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      <div
        ref={menuRef}
        className={`fixed top-0 right-0 w-3/4 h-screen bg-black z-50 transform transition-transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button
          onClick={toggleMenu}
          type="button"
          className="absolute top-5 right-5 text-white"
          ref={closeButtonRef}
        >
          <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="flex h-full items-center justify-center">
          <div className="flex flex-col items-center justify-around h-[50vh] text-white">
            <Hlink smooth to="/#programs">
              <a className="text-2xl py-4">Programs</a>
            </Hlink>
            <Hlink smooth to="/#how-it-works">
              <a className="text-2xl py-4">How it works?</a>
            </Hlink>
            <Hlink smooth to="/#pricing">
              <a className="text-2xl py-4">Pricing</a>
            </Hlink>
            <Hlink smooth to="/#faq">
              <a className="text-2xl py-4">FAQ</a>
            </Hlink>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
